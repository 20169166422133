Application.Controller.Group = (function($)
{
    function edit()
    {
    }

    // Per page
    $('#a_group_feature').on('change', function() {
        var elem = $(this);
        if (elem.val() == 1) {
          $('#a_group_template').show();
          $('#a_group_template').prop('required',true);
          $('label[for=a_group_template]').show();
        } else {
          $('#a_group_template').hide();
          $('#a_group_template').prop('required',false);
          $('#a_group_template').val('');
          $('label[for=a_group_template]').hide();
        }
    });

    return {
        'edit': edit
    };
})(jQuery);
